import React from 'react'

const FAQ = () => {
    return (
        <>
            <section className="faq--layout1" data-bg-src="assets/img/shapes/faq-bg.svg">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 mx-auto">
                            <div className="title-area text-center">
                                <span className="sec-subtitle style4">FAQs</span>
                                <h2 className="sec-title" style={{fontSize:"36px"}}>Frequently Asked Questions</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-10 mx-auto">
                            <div className="accordion-style1">
                                <div className="accordion" id="accordionExample">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                How can I buy a bike from Auto World Bangalore?
                                            </button>
                                        </h2>
                                        <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                Buying used bikes from Auto World Bangalore is quick and easy. By visiting the online website of Auto World Bangalore you can witness the large range of bikes listed in order. Choose your location and set your budget this helps you to narrow down your buying options for used two wheelers. Check out the list of bikes available to fit in your budget and the maximum you can stretch to get a good bike, Auto World Bangalore will help you out.
                                                Fill in the details accurately; Auto World Bangalore executive will get in touch with you shortly. If you wish to physically see the second hand two wheeler then visit the nearby showroom using the showroom locator on the website. You can book a test drive at home by just paying 399 INR. Understand the positive highlights of the used bikes whether it fits your comfortability, feels good and meets your expectations or not! Once you finalize choosing the preferred used two wheeler, further Auto World Bangalore executive will guide you to process your buying parameters.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                Auto World Bangalore has showrooms in which cities??
                                            </button>
                                        </h2>
                                        <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                Auto World Bangalore is one of the most preferred omnichannel consumer brands entrusted to buying and selling used bikes. Being a pioneer in the largely unorganized industry, Auto World Bangalore has set up multiple showrooms spread across different cities to serve the customers from time to time. Auto World Bangalore caters to cities like Bangalore, Pune, Delhi NCR, Mumbai, Jaipur, Chittorgarh, Faridabad, Bhilwara, Gandhinagar, Noida, Gurgaon, Mysore, Pune, Thane, and Ahmedabad..
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                How to buy a Scooty from Auto World Bangalore?
                                            </button>
                                        </h2>
                                        <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                By visiting the online website of Auto World Bangalore you can witness the large range of scooters listed in order. Choose your location and set your budget to narrow down your buying options for used two wheeler. Check out the list of used scooty available to fit in your budget. The top brands include Honda Activa, Honda Dio, Suzuki Access, Yamaha Ray, TVS pep+, TVS Jupiter and more.

                                                Fill in the details accurately, further a Auto World Bangalore executive will get in touch with you. With the doorstep delivery service, you can now enjoy a trial run for the second hand scooty. A test drive can be booked by just paying Rs. 399. Once you finalize choosing the preferred used two-wheeler, a Auto World Bangalore executive will guide you to process your buying parameters.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                How does Auto World Bangalore’s doorstep service help?
                                            </button>
                                        </h2>
                                        <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                Transparency, Trust, and hassle-free service for the buyer/seller is our priority. Auto World Bangalore is one of the Most Preferred omnichannel consumer brands entrusted to buying and selling used bikes. We love to upgrade with the growing trend making everything customer-friendly. To ease the buying, selling and servicing of bikes, we have made the doorstep option available to all. The consumers can find a bike on the website and schedule a test drive from home.

                                                Amidst the pandemic, this doorstep feature has made buying and selling of second hand two-wheelers easy. You can even service your bike by getting in touch with the Auto World Bangalore executive for further process.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}

export default FAQ