import React from 'react'
import SliderBrands from '../../component/slidercomponent/SliderBrands'

const Brands = () => {
    return (
        <>

            <section className="brand--layout1">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-8 mx-auto">
                            <div className="title-area text-center">
                                <span className="sec-subtitle style4">Brands</span>
                                {/* <h2 style={{ fontSize: "32px" }} className="sec-title">One Stop For Countless Benefits - Buy At Home</h2> */}
                            </div>
                        </div>
                    </div>
                    <div className="row vs-carousel text-center" data-slide-show={4} data-md-slide-show={3} data-sm-slide-show={2} data-xs-slide-show={1}>
                        <SliderBrands />
                    </div>
                </div>
            </section>

        </>
    )
}

export default Brands