import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';


import 'swiper/css';



import { Autoplay } from 'swiper/modules';

export default function SliderHomeBanner() {
    return (
        <>
            <Swiper
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}


                modules={[Autoplay]}
                className="mySwiper"
            >


                {/* <SwiperSlide>
                    <div className="">
                        <img src="./assets/img/car/banner_04.JPG" className="" alt="ministry" />
                    </div>
                </SwiperSlide> */}
                <SwiperSlide>
                    <div className="">
                        <img src="./assets/img/car/banner_01.JPG" className="" alt="ministry" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="">
                        <img src="./assets/img/car/banner_02.JPG" className="" alt="ministry" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="">
                        <img src="./assets/img/car/banner_03.JPG" className="" alt="ministry" />
                    </div>
                </SwiperSlide>




            </Swiper>
        </>
    );
}
