import React from "react";

const WhatsappIcon = () => {
  return (
    <>
      <div className="whatsapp_float">
        {/* <a href="https://wa.me/919060059444" target="_blank"> */}
        <a href="https://wa.me/c/919060059444" target="_blank">
          <img
            src="./assets/img/car/WhatsappLogo.png"
            width="50px"
            className="whatsapp_float_btn"
          />
        </a>
      </div>
    </>
  );
};

export default WhatsappIcon;
