import React from 'react'

const OneStop = () => {
    return (
        <>

            <section className="process--layout1 z-index-common space-extra-bottom" data-bg-src="assets/img/bg/process-bg-1.svg">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-8 mx-auto">
                            <div className="title-area text-center">
                                <span className="sec-subtitle style4">Buy At Home</span>
                                <h2 style={{ fontSize: "32px" }} className="sec-title">One Stop For Countless Benefits - Buy At Home</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="process-block--style2 col-lg-3 col-md-6">
                            <div className="process-block__body">
                                <div className="process-block__icon">
                                    <img src="assets/img/car/buy-bikee.3bbdc3a1.svg" alt="process icon 2 1" />
                                    <span className="process-block__number">01</span>
                                </div>
                                <div className="process-block__content">
                                    <h2 className="process-block__title">Buy Used Bikes</h2>
                                    <p className="process-block__text">
                                    Buy the best quality used bikes from Auto world bangalore at a reasonable price.</p>
                                </div>
                            </div>
                        </div>
                        <div className="process-block--style2 col-lg-3 col-md-6">
                            <div className="process-block__body">
                                <div className="process-block__icon">
                                    <img src="assets/img/car/ownFranchise.d762ac04.svg" style={{width:"80px", height:"80px"}} alt="process icon 2 1" />
                                    <span className="process-block__number">02</span>
                                </div>
                                <div className="process-block__content">
                                    <h2 className="process-block__title">
                                        Sell Your Bike</h2>
                                    <p className="process-block__text">
                                    Sell your bike to Auto world bangalore at the best price with instant payment.</p>
                                </div>
                            </div>
                        </div>
                        <div className="process-block--style2 col-lg-3 col-md-6">
                            <div className="process-block__body">
                                <div className="process-block__icon">
                                    <img src="assets/img/car/sell-icon.ed7ac914.svg" alt="process icon 2 1" />
                                    <span className="process-block__number">03</span>
                                </div>
                                <div className="process-block__content">
                                    <h2 className="process-block__title">own a franchise</h2>
                                    <p className="process-block__text">
                                    Launch your own business by owning a franchise with comprehensive support and quick returns.</p>
                                </div>
                            </div>
                        </div>
                        <div className="process-block--style2 col-lg-3 col-md-6">
                            <div className="process-block__body">
                                <div className="process-block__icon">
                                    <img src="assets/img/car/serviceIconNew.2220ec19.svg" alt="process icon 2 1" />
                                    <span className="process-block__number">04</span>
                                </div>
                                <div className="process-block__content">
                                    <h2 className="process-block__title">Exchange your bike
                                    </h2>
                                    <p className="process-block__text">Exchange your bike seamlessly and book a new bike without any hassle with Auto world bangalore</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default OneStop