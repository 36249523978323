import React from 'react'
import HomeBanner from './home/HomeBanner'
import OneStop from './home/OneStop'
import TrustedShowroom from './home/TrustedShowroom'
import HomeTestimonial from './home/HomeTestimonial'
import FAQ from './home/FAQ'
import HomeAbout from './home/HomeAbout'
import Brands from './home/Brands'

const Home = () => {
  return (
    <>

      <HomeBanner />
      <OneStop />
      <Brands />
      <TrustedShowroom />
      <HomeAbout />
      <HomeTestimonial />
      <FAQ />
  




    </>
  )
}

export default Home