import React from 'react'

const TrustedShowroom = () => {
    return (
        <>


            <section className="service--layout1 space-top z-index-common" data-bg-src="assets/img/bg/service-bg-1-1.jpg">
                <div className="overlay3" />
                <div className="container">
                    <div className="row align-items-end">
                        <div className="col-xl-12 col-lg-12 col-auto">
                            <div className="title-area white-title">
                                <span className="sec-subtitle">Auto World Bangalore</span>
                                <h2 className="sec-title" style={{ fontSize: "30px" }}>Auto World Bangalore Showroom - Trusted Showroom For Used Bikes</h2>
                            </div>
                        </div>

                    </div>
                    <div className="row service-carousel vs-carousel" data-slide-show={3} data-md-slide-show={2} data-sm-slide-show={1} data-xs-slide-show={1}>
                        <div className="col-lg-4">
                            <div className="service-block--style2">
                                <div className="service-block__thumb">
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    {/* <img src="assets/img/service/service-1-1.jpg" alt="services" /> */}
                                </div>
                                <div className="service-block__content">
                                    <div className="service-block__icon" data-bg-src="assets/img/shapes/shape-1.svg">
                                        {/* <img src="assets/img/car/ribbon.svg" alt="icon" /> */}
                                        <img src="assets/img/car/expert-icon.svg" alt="icon" />


                                    </div>
                                    <h3 className="service-block__title h5"><a to="service-details.html">Certified by Auto Experts on 120+ Inspection Points</a></h3>
                                    <p className="service-block__text">Choose from 100% Certified Bikes only. All bikes are thoroughly tested and certified by Auto Experts who come with 10+ years of Industry Experience.</p>
                                    <br />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="service-block--style2">
                                <div className="service-block__thumb">
                                    {/* <img src="assets/img/service/service-1-2.jpg" alt="services" /> */}
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                </div>
                                <div className="service-block__content">
                                    <div className="service-block__icon" data-bg-src="assets/img/shapes/shape-1.svg">

                                        <img src="assets/img/car/ribbon.svg" alt="icon" />

                                    </div>
                                    <h3 className="service-block__title h5"><a to="service-details.html">Free 6 Months Comprehensive Warranty worth Rs.5000</a></h3>
                                    <p className="service-block__text">Secure your bike for the future.Experience the best in class warranty covering the critical bike parts including Engine & Gearbox. Now that’s something you can’t put a price on.</p>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="service-block--style2">
                                <div className="service-block__thumb">
                                    {/* <img src="assets/img/service/service-1-3.jpg" alt="services" /> */}
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                </div>
                                <div className="service-block__content">
                                    <div className="service-block__icon" data-bg-src="assets/img/shapes/shape-1.svg">
                                        <img src="assets/img/car/credr-check.svg" alt="icon" />

                                    </div>
                                    <h3 className="service-block__title h5"><a to="service-details.html">Free 7-Day Buy Protect</a></h3>
                                    <p className="service-block__text">Get covered with our flagship product.Any unforeseen issues you face in a week's time will be resolved free of cost. Ride out worry-free on your Auto World Bangalore bike.</p>
                                    <br />
                                    <br />
                                    <br />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="counter--layout1">
                <div className="container">
                    <div className="counter-inner z-index-common" data-bg-src="assets/img/bg/counter-bg.jpg">
                        <div className="overlay4" />
                        <div className="row justify-content-between">
                            <div className="col-lg-auto col-md-6 mb-30">
                                <div className="counter-block count-start">
                                    <div className="counter-block__icon">
                                        <img src="assets/img/icons/counter-icon-1.svg" alt="counter icon 1" />
                                    </div>
                                    <div className="counter-block__body">
                                        <span className="counter-block__number"><span className="counters" data-counter={1}></span> 24k+</span>
                                        <h3 className="counter-block__heading">Happy Customers</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-auto col-md-6 mb-30">
                                <div className="counter-block count-start">
                                    <div className="counter-block__icon">
                                        <img src="assets/img/icons/counter-icon-2.svg" alt="counter icon 1" />
                                    </div>
                                    <div className="counter-block__body">
                                        <span className="counter-block__number"><span className="counters" data-counter={10}></span>10 +</span>
                                        <h3 className="counter-block__heading">Years of Experience</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-auto col-md-6 mb-30">
                                <div className="counter-block count-start">
                                    <div className="counter-block__icon">
                                        <img src="assets/img/icons/counter-icon-3.svg" alt="counter icon 1" />
                                    </div>
                                    <div className="counter-block__body">
                                        <span className="counter-block__number"><span className="counters" data-counter={1499}></span> 10k</span>
                                        <h3 className="counter-block__heading">Vehicles Sold</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-auto col-md-6 mb-30">
                                <div className="counter-block count-start">
                                    <div className="counter-block__icon">
                                        <img src="assets/img/icons/counter-icon-3.svg" alt="counter icon 1" />
                                    </div>
                                    <div className="counter-block__body">
                                        <span className="counter-block__number"><span className="counters" data-counter={15}></span> 15 +</span>
                                        <h3 className="counter-block__heading">Awards Wining</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}

export default TrustedShowroom