import React from 'react'
import { Link } from "react-router-dom";
import HomeTestimonial from '../home/HomeTestimonial';
import Brands from '../home/Brands';


const AboutUs = () => {
  return (
    <>
      <div className="breadcumb-wrapper" style={{ background: "url(assets/img/car/newService.5e2f082d.svg)" }}>
        <div className="container z-index-common">
          <div className="breadcumb-content">
            <h1 className="breadcumb-title">About Us</h1>
            <div className="breadcumb-menu-wrap">
              <ul className="breadcumb-menu">
                <li><Link to="/">Home</Link></li>
                <li>About Us</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className=" space-extra-bottom" data-bg-src="assets/img/bg/about-bg.png">
        <div className="container">
          <div className="row align-items-center gx-60">
            <div className="col-lg-6">
              <div className="about-img3 position-relative">
                <div className="img1">
                  <img src="assets/img/car/bike.png" alt="about img 1 1" />
                </div>
                {/* <div className="img-right">
                  <div className="img2">
                    <img src="assets/img/car/Royal Enfield.webp" alt="about img 1 1" />
                  </div>
                  <div className="img3">
                    <img src="assets/img/car/TVS Apache.webp" alt="about img 1 1" />
                  </div>
                </div> */}
                {/* <div className="text-box style2">
                  <span className="text-box__number">10+</span>
                  <h3 className="text-box__title" style={{fontSize:"20px"}}>Years of Experience</h3>
                </div> */}
              </div>
            </div>
            <div className="col-lg-6 mb-30 mt-lg-5">
              <span className="sec-subtitle style2">About Us</span>
              <h2 className="sec-title" style={{fontSize:"25px"}}>Get A New Experience With Auto World Bangalore Services</h2>
              <p>Over the years, we at Auto World Bangalore have striven to create a fair, engaging and people friendly workplace. We are always on the look-out for people who believe in putting the customer first while constantly adapting to the changing world and pursuing excellence.</p>
              <p>Auto World Bangalore mission is to bring delight in two-wheeler buying, we offer a bouquet of reliable tools and services to help motorcycle and scooter consumers decide on buying the right two-wheeler, at the right price and from the right partner.</p>
              <div className="progress-box">
                <h3 className="progress-box__title">Happy Customers</h3>
                <span className="progress-box__number">99%</span>
                <div className="progress-box__progress">
                  <div className="progress-box__bar" role="progressbar" style={{ width: '95%' }} aria-valuenow={80} aria-valuemin={0} aria-valuemax={100} />
                </div>
              </div>
              <div className="progress-box">
                <h3 className="progress-box__title">Awards Wining</h3>
                <span className="progress-box__number">15+</span>
                <div className="progress-box__progress">
                  <div className="progress-box__bar" role="progressbar" style={{ width: '87%' }} aria-valuenow={62} aria-valuemin={0} aria-valuemax={100} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="counter--layout2 space-top space-extra-bottom">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-auto col-md-6 mb-30">
              <div className="counter-block style2 count-start">
                <div className="counter-block__icon">
                  <img src="assets/img/icons/counter-icon-a-1.svg" alt="counter icon 1" />
                </div>
                <div className="counter-block__body">
                  <span className="counter-block__number"><span className="counters" data-counter={1}></span>24k+</span>
                  <h3 className="counter-block__heading">Happy Customers</h3>
                </div>
              </div>
            </div>
            <div className="col-lg-auto col-md-6 mb-30">
              <div className="counter-block style2 count-start">
                <div className="counter-block__icon">
                  <img src="assets/img/icons/counter-icon-a-2.svg" alt="counter icon 1" />
                </div>
                <div className="counter-block__body">
                  <span className="counter-block__number"><span className="counters" data-counter={10}>10</span>+</span>
                  <h3 className="counter-block__heading">Years of Experience</h3>
                </div>
              </div>
            </div>
            <div className="col-lg-auto col-md-6 mb-30">
              <div className="counter-block style2 count-start">
                <div className="counter-block__icon">
                  <img src="assets/img/icons/counter-icon-a-3.svg" alt="counter icon 1" />
                </div>
                <div className="counter-block__body">
                  <span className="counter-block__number"><span className="counters" data-counter={1499}></span>10k+</span>
                  <h3 className="counter-block__heading">Vehicles Sold</h3>
                </div>
              </div>
            </div>
            <div className="col-lg-auto col-md-6 mb-30">
              <div className="counter-block style2 count-start">
                <div className="counter-block__icon">
                  <img src="assets/img/icons/counter-icon-a-4.svg" alt="counter icon 1" />
                </div>
                <div className="counter-block__body">
                  <span className="counter-block__number"><span className="counters" data-counter={15}>15</span>+</span>
                  <h3 className="counter-block__heading">Awards Wining</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <br/>
      <Brands />
      <HomeTestimonial />




    </>
  )
}

export default AboutUs