import React from 'react'
import SliderHomeTestimonial from '../../component/slidercomponent/SliderHomeTestimonial'

const HomeTestimonial = () => {
    return (
        <>

            <section className="client--layout3 space-top space-extra-bottom" data-bg-src="assets/img/bg/client-3-1.png">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-7 mx-auto">
                            <div className="title-area text-center ">
                                <span className="sec-subtitle style4">Testimonials</span>
                                <h2 className="sec-title">Our Awesome Customers Valuable Feedback</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row vs-carousel" data-slide-show={4} data-lg-slide-show={3} data-md-slide-show={2} data-sm-slide-show={1} data-xs-slide-show={1}>
                        <SliderHomeTestimonial />
                    </div>
                </div>
            </section>

        </>
    )
}

export default HomeTestimonial