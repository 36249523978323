import React from 'react'
import { Link } from "react-router-dom";


const Sell = () => {
    return (
        <>

            <div className="breadcumb-wrapper" style={{ background: "url(assets/img/car/newService.5e2f082d.svg)" }}>
                <div className="container z-index-common">
                    <div className="breadcumb-content">
                        <h1 className="breadcumb-title">Selling Your Motorcycle</h1>
                        <div className="breadcumb-menu-wrap">
                            <ul className="breadcumb-menu">
                                <li><Link to="/">Home</Link></li>
                                <li>Selling Your Motorcycle</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <section className="about--layout1 space-top space-extra-bottom">
                <div className="container">
                    <div className="row align-items-center ">
                        <div className="col-lg-6 mb-30">
                            <div className="about-img2">
                                <div className="element2" />
                                <div className="position-relative overflow-hidden">
                                    <img src="assets/img/car/s1.jpg" alt="about img 1 1" className="img1 reveal1 it-reveal-animation" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 mb-30">
                            <span className="sec-subtitle style2">Auto World Bangalore</span>
                            <h2 className="sec-title" style={{ fontSize: "28px" }}>Fill Out Our Form</h2>
                            <p>Complete either Sell form with all the information about you and your motorcycle. If you aren’t sure which one you want to do fill out the  our Bike Buying Team can help you figure out which method is best for you.</p>
                        </div>
                    </div>
                    <div className="row align-items-center ">
                        <div className="col-lg-6 mb-30">
                            <span className="sec-subtitle style2">Auto World Bangalore</span>
                            <h2 className="sec-title" style={{ fontSize: "28px" }}>Phone Call From Our Bike Buying Team
                            </h2>
                            <p> Once you have filled out our form a member of our Bike Buying Team will reach out to you for any more information needed about your motorcycle, including photos and videos. On the phone our team will discuss current for your motorcycle as well as what we are willing to offer </p>
                        </div>
                        <div className="col-lg-6 mb-30">
                            <div className="about-img2">
                                <div className="element2" />
                                <div className="position-relative overflow-hidden">
                                    <img src="assets/img/car/s2.jpg" alt="about img 1 1" className="img1 reveal1 it-reveal-animation" />
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="row align-items-center ">
                        <div className="col-lg-6 mb-30">
                            <div className="about-img2">
                                <div className="element2" />
                                <div className="position-relative overflow-hidden">
                                    <img src="assets/img/car/s2.jpg" alt="about img 1 1" className="img1 reveal1 it-reveal-animation" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 mb-30">
                            <span className="sec-subtitle style2">Auto World Bangalore</span>
                            <h2 className="sec-title" style={{ fontSize: "28px" }}>Schedule Transportation and Payment</h2>
                            <p>Upon agreement of a price, we will schedule transportation. Depending on where your motorcycle is located, we can either schedule shipping or pick up. Payment and title transfer will also be arranged
                            </p>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Sell