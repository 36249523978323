import React from 'react'
import { Link } from "react-router-dom";


const Buy = () => {
    return (
        <>

            <div className="breadcumb-wrapper" style={{ background: "url(assets/img/car/newService.5e2f082d.svg)" }}>
                <div className="container z-index-common">
                    <div className="breadcumb-content">
                        <h1 className="breadcumb-title">Buying  Used Motorcycle</h1>
                        <div className="breadcumb-menu-wrap">
                            <ul className="breadcumb-menu">
                                <li><Link to="/">Home</Link></li>
                                <li>Buying Used Motorcycle</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <section className="about--layout1 space-top space-extra-bottom">
                <div className="container">
                    <div className="row align-items-center ">
                        <div className="col-lg-6 mb-30">
                            <div className="about-img2">
                                <div className="element2" />
                                <div className="position-relative overflow-hidden">
                                    <img src="assets/img/car/Pick Out.jpg" alt="about img 1 1" className="img1 reveal1 it-reveal-animation" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 mb-30">
                            <span className="sec-subtitle style2">Auto World Bangalore</span>
                            <h2 className="sec-title" style={{ fontSize: "28px" }}>Pick Out Your Bike</h2>
                            <p>Start the search for your dream motorcycle by using our 360-degree viewing tool to see just what the view looks like from the rider’s seat! Need more photos or videos? Just ask! We will get you any information about the motorcycle you need to feel comfortable buying it online.</p>
                        </div>
                    </div>
                    <div className="row align-items-center ">
                        <div className="col-lg-6 mb-30">
                            <span className="sec-subtitle style2">Auto World Bangalore</span>
                            <h2 className="sec-title" style={{ fontSize: "28px" }}>Value Your Trade</h2>
                            <p>Have a motorcycle to trade in? Fill out our trade in form and our expert bike buyers will give you a quote for your bikes value within 24 hours. We offer competitive pricing on motorcycles from all the major manufacturers</p>
                        </div>
                        <div className="col-lg-6 mb-30">
                            <div className="about-img2">
                                <div className="element2" />
                                <div className="position-relative overflow-hidden">
                                    <img src="assets/img/car/s01.jpg" alt="about img 1 1" className="img1 reveal1 it-reveal-animation" />
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="row align-items-center ">
                        <div className="col-lg-6 mb-30">
                            <div className="about-img2">
                                <div className="element2" />
                                <div className="position-relative overflow-hidden">
                                    <img src="assets/img/car/s02.jpg" alt="about img 1 1" className="img1 reveal1 it-reveal-animation" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 mb-30">
                            <span className="sec-subtitle style2">Auto World Bangalore</span>
                            <h2 className="sec-title" style={{ fontSize: "28px" }}>Secure Your Motorcycle</h2>
                            <p>After you’ve selected the perfect ride, secure your motorcycle with a refundable $500 deposit. After we receive your deposit, you have five days to finalize your payment. If you don't purchase the motorcycle after the five days, we refund your entire deposit.
                            </p>
                        </div>
                    </div>
                    <div className="row align-items-center ">
                        <div className="col-lg-6 mb-30">
                            <span className="sec-subtitle style2">Auto World Bangalore</span>
                            <h2 className="sec-title" style={{ fontSize: "28px" }}>Protect Your Motorcycle</h2>
                            <p>Have peace of mind and ride without worry by getting a warranty on your motorcycle. We offer a wide range of options allowing you to personalize your coverage. If you have any questions or concerns our staff is able to help you choose the best option for you and your motorcycle</p>
                        </div>
                        <div className="col-lg-6 mb-30">
                            <div className="about-img2">
                                <div className="element2" />
                                <div className="position-relative overflow-hidden">
                                    <img src="assets/img/car/s03.jpg" alt="about img 1 1" className="img1 reveal1 it-reveal-animation" />
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="row align-items-center ">
                        <div className="col-lg-6 mb-30">
                            <div className="about-img2">
                                <div className="element2" />
                                <div className="position-relative overflow-hidden">
                                    <img src="assets/img/car/s04.jpg" alt="about img 1 1" className="img1 reveal1 it-reveal-animation" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 mb-30">
                            <span className="sec-subtitle style2">Auto World Bangalore</span>
                            <h2 className="sec-title" style={{ fontSize: "28px" }}>Documents & Paperwork</h2>
                            <p>your motorcycle purchase comes with a license plate. All of our motorcycles are state registered during the purchase process and are given a temporary license plate upon arrival.
                                In order to ensure accuracy of information on all purchase and loan documents, we schedule a mobile notary to your home or place of business with all the needed documents. Rest assured, we can accommodate almost any schedule to make the signing experience as convenient as possible.  In order to protect both our customers and vendors, you are required to provide a valid driver's license or state identification card at the time of signing. Additionally, you will receive a checklist from the title company outlining any additional requirements needed in your state. These requirements will help you get your license plate back as quickly as possible!
                            </p>
                        </div>
                    </div>
                    <div className="row align-items-center ">
                        <div className="col-lg-6 mb-30">
                            <span className="sec-subtitle style2">Auto World Bangalore</span>
                            <h2 className="sec-title" style={{ fontSize: "28px" }}>Finalize Payment</h2>
                            <p>You’re almost ready to ride! To finalize your financing, we’ll schedule a one-on-one call with you to review next steps for payment including collection or wire transfer instructions. Once payment is received and/or cleared through your financial institution, we schedule your motorcycle delivery right to your doorstep. Note, if payment is collected via credit card, debit card, wire transfers or checks, your ride will be scheduled for delivery once all funds are received and any hold periods are cleared.
                            </p>
                        </div>
                        <div className="col-lg-6 mb-30">
                            <div className="about-img2">
                                <div className="element2" />
                                <div className="position-relative overflow-hidden">
                                    <img src="assets/img/car/s05.jpg" alt="about img 1 1" className="img1 reveal1 it-reveal-animation" />
                                </div>
                            </div>
                        </div>

                    </div>
                   
                </div>
            </section>

        </>
    )
}

export default Buy