import React, { useState } from 'react'
import emailjs from "@emailjs/browser";
import { Link } from "react-router-dom";



const Contact = () => {

    const [name, setName] = useState("");
    const [mobile, setMobile] = useState("");
    const [mail, setmail] = useState("");
    const [massages, setMassages] = useState("");

    const nameChange = (e) => {
        const value = e.target.value;
        setName(value);
    };
    const mobileChange = (e) => {
        const value = e.target.value;
        setMobile(value);
    };
    const mailChange = (e) => {
        const value = e.target.value;
        setmail(value);
    };
    const massagesChange = (e) => {
        const value = e.target.value;
        setMassages(value);
    };
    const sendEmail = (e) => {
        e.preventDefault();
        emailjs
            .sendForm(
                "service_9dzi3x6",
                "template_wuxrxj4",
                "#myForm",
                "8i7i_APkS7sZ9CqPl"
            )
            .then(
                function (response) {
                    console.log("SUCCESS!", response.status, response.text);
                    if (response.text === "OK") {
                        setName("");
                        setMobile("");
                        setmail("");
                        setMassages("");
                    }
                },
                function (error) {
                    console.log("FAILED...", error);
                }
            );
    };

    return (
        <>
            <div>
                <div className="breadcumb-wrapper" style={{ background: "url(assets/img/car/newService.5e2f082d.svg)" }}>
                    <div className="container z-index-common">
                        <div className="breadcumb-content">
                            <h1 className="breadcumb-title">Contact Us</h1>
                            <div className="breadcumb-menu-wrap">
                                <ul className="breadcumb-menu">
                                    <li><Link to="/">Home</Link></li>
                                    <li>Contact Us</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="space-top space-extra-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5 mb-30">
                                <div className="title-area">
                                    <span className="sec-subtitle style2">Contact Us</span>
                                    <h2 style={{ fontSize: "32px" }} className="sec-title">Fill in your details &  <span>we’ll call you back.</span></h2>
                                </div>
                                <div className="mb-40">
                                    <div className="icon-box style3">
                                        <div className="icon-box__icon">
                                            <i className="fas fa-phone-alt" />
                                        </div>
                                        <div>
                                            <p className="icon-box__text">Call Us 24/7</p>
                                            <h3 className="icon-box__title"><a href="tel:+919060059444">+91 9060059444</a></h3>
                                        </div>
                                    </div>
                                    <div className="icon-box style3">
                                        <div className="icon-box__icon">
                                            <i className="fas fa-envelope" />
                                        </div>
                                        <div>
                                            <p className="icon-box__text">Email Us</p>
                                            <h3 className="icon-box__title"><a href="mailto:blr560029@gmail.com">blr560029@gmail.com</a></h3>
                                        </div>
                                    </div>
                                    <div className="icon-box style3">
                                        <div className="icon-box__icon">
                                            <i className="fas fa-map-marker-alt" />
                                        </div>
                                        <div>
                                            <p className="icon-box__text">Location</p>
                                            <h3 className="icon-box__title">
                                                <a href='https://maps.app.goo.gl/xQbmGeTHfVmtrwou7' target="_blank">
                                                    <p style={{ fontSize: "18px", color: "black" }}>Metro Pllar 176, Bmtc Bus Terminal, No 298, Outer Ring Rd, Opp. Btm, Old Madiwala, Madiwala, 1st Stage, Btm Layout, Bengaluru, Karnataka 560068</p>
                                                </a>
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                                <hr className="divider2" />
                                <div className="social-style1 style3">
                                    <span className="title">Follow Us:</span>
                                    <a to="#" target="_blank"><i className="fab fa-facebook-f" /></a>
                                    <a to="#" target="_blank"><i className="fab fa-instagram" /></a>
                                    <a to="#" target="_blank"><i className="fab fa-pinterest-p" /></a>
                                    <a to="#" target="_blank"><i className="fab fa-twitter" /></a>
                                </div>
                            </div>
                            <div className="col-lg-7 mb-30">
                                <form action="mail.php" method="post" className="form-style3 ajax-contact">
                                    <h3 className="sec-title">Send Us a Message</h3>
                                    <div className="row">
                                        <div className="col-md-6 form-group">
                                            <input name="fname" type="text" className="form-control" placeholder="First Name" required />
                                        </div>
                                        <div className="col-md-6 form-group">
                                            <input name="lname" type="text" className="form-control" placeholder="Last Name" required />
                                        </div>
                                        <div className="col-12 form-group">
                                            <input name="email" type="email" className="form-control" placeholder="Email Address" required />
                                        </div>
                                        <div className="col-12  form-group">
                                            <textarea name="message" className="form-control" placeholder="Message" required />
                                        </div>
                                        <a to=''>
                                            <div className="col-12 form-group">

                                                <button className="vs-btn" type="submit">
                                                    Send Message
                                                </button>

                                            </div>
                                        </a>
                                    </div>
                                </form>
                                <p className="form-messages mb-0 mt-3" />
                            </div>
                        </div>
                    </div>
                </section>
                <div className="space-bottom">
                    <div className="container">
                        <div style={{ maxWidth: '100%', listStyle: 'none', transition: 'none', overflow: 'hidden', width: 1200, height: 400 }}><div id="my-map-canvas" style={{ height: '100%', width: '100%', maxWidth: '100%' }}><iframe style={{ height: '100%', width: '100%', border: 0 }} frameBorder={0} src="https://www.google.com/maps/embed/v1/place?q=Auto+world+bangalore+Metro+Pllar+176,+Bmtc+Bus+Terminal,+No+298,+Outer+Ring+Rd,+Opp.+Btm,+Old+Madiwala,+Madiwala,+1st+Stage,+Btm+Layout,+Bengaluru,+Karnataka+560068&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8" /></div><a className="googlecoder" href="https://www.bootstrapskins.com/themes" id="get-map-data">premium bootstrap themes</a><style dangerouslySetInnerHTML={{ __html: "#my-map-canvas img{max-height:none;max-width:none!important;background:none!important;}" }} /></div>

                    </div>
                </div>
            </div>




        </>
    )
}

export default Contact