import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';

import { Link } from "react-router-dom";


export default function SliderBrands() {
    return (
        <>
            <Swiper
                slidesPerView={1}
                spaceBetween={10}

                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                breakpoints={{
                    '@0.00': {
                        slidesPerView: 2,
                        spaceBetween: 10,
                    },
                    '@0.75': {
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                    '@1.00': {
                        slidesPerView: 5,
                        spaceBetween: 40,
                    },
                    '@1.50': {
                        slidesPerView: 6,
                        spaceBetween: 0,
                    },
                }}
                modules={[Autoplay]}
                className="mySwiper"
            >


                <SwiperSlide>
                    <div className="col-auto">
                        <div className="brand-block"><img src="assets/img/car/01.png" alt="Brand" /></div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="col-auto">
                        <div className="brand-block"><img src="assets/img/car/02.png" alt="Brand" /></div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="col-auto">
                        <div className="brand-block"><img src="assets/img/car/03.png" alt="Brand" /></div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="col-auto">
                        <div className="brand-block"><img src="assets/img/car/04.png" alt="Brand" /></div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="col-auto">
                        <div className="brand-block"><img src="assets/img/car/05.png" alt="Brand" /></div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="col-auto">
                        <div className="brand-block"><img src="assets/img/car/07.png" alt="Brand" /></div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="col-auto">
                        <div className="brand-block"><img src="assets/img/car/08.png" alt="Brand" /></div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="col-auto">
                        <div className="brand-block"><img src="assets/img/car/09.png" alt="Brand" /></div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="col-auto">
                        <div className="brand-block"><img src="assets/img/car/10.png" alt="Brand" /></div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="col-auto">
                        <div className="brand-block"><img src="assets/img/car/11.png" alt="Brand" /></div>
                    </div>
                </SwiperSlide>


            </Swiper>
        </>
    );
}
