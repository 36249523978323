import React from 'react'
import { Link } from "react-router-dom";



const HomeAbout = () => {
    return (
        <>

            <section className="about--layout1 space-top space-extra-bottom">
                <div className="container">
                    <div className="row align-items-center ">
                        <div className="col-lg-6 mb-30">
                            <div className="about-img2">
                                <div className="element2" />
                                <div className="position-relative overflow-hidden">
                                    <a className="play-btn2 popup-video" to="https://www.youtube.com/watch?v=zX-jSCDsJ8E">
                                        <span>
                                            <svg width={19} height={21} viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0.634766 10.2592V3.76308C0.634766 1.07168 3.54883 -0.611916 5.87695 0.73574L11.5059 3.98574L17.1348 7.23574C19.4668 8.57949 19.4668 11.9467 17.1348 13.2904L11.5059 16.5404L5.87695 19.7904C3.54883 21.1303 0.634766 19.4506 0.634766 16.7592V10.2592Z" fill="white" />
                                            </svg>
                                        </span>
                                    </a>
                                    <img src="assets/img/car/about.AVIF" alt="about img 1 1" className="img1 reveal1 it-reveal-animation" />
                                </div>
                                <div className="text-box">
                                    <span className="text-box__number">10+</span>
                                    <h3 className="text-box__title">Years of Experience</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 mb-30">
                            <span className="sec-subtitle style2">Service</span>
                            <h2  style={{fontSize:"32px"}} className="sec-title">Bike Service at your doorstep with Auto World Bangalore</h2>
                            <p style={{textAlign:"justify"}}>Regular two-wheeler service is the key for maintaining top-class bike performance. Often, it isn't easy to go out to the bike service stations, every 3 months for the service. to brings you the most helpful and best doorstep bike service to ease this process. It is accessible in all the top locations like Bangalore and Pune. Earlier bike service at home was a dream for the bikers. Now, the doorstep bike service has come to reality because of to.</p>
                            <p style={{textAlign:"justify"}}>There is no need for you to search for expert mechanics and technicians as we have arranged that for you. All you need to do is book an online bike service through Auto World Bangalore App or website and get your bike servicing time fixed. An expert mechanic will come to your doorstep at your convenient time and date to take care of your bike. There is no added or hidden cost to this service. It is transparent and will be shared with you if any serious problem is there with your bike. No hassle of service stations or service tips is required.</p>
                           
                            {/* <a to="service-details.html" className="vs-btn">Learn More <span className="vs-btn__bar" /></a> */}
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}

export default HomeAbout