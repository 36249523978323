import React from 'react'
import { Link } from "react-router-dom";
import SliderHomeBanner from '../../component/slidercomponent/SliderHomeBanner';


const HomeBanner = () => {
    return (
        <>
            <SliderHomeBanner />

        </>
    )
}

export default HomeBanner