import React from 'react'
import { Link } from "react-router-dom";


const Blogs = () => {
    return (
        <>
            <div className="breadcumb-wrapper" style={{ background: "url(assets/img/car/newService.5e2f082d.svg)" }}>
                <div className="container z-index-common">
                    <div className="breadcumb-content">
                        <h1 className="breadcumb-title">Blogs</h1>
                        <div className="breadcumb-menu-wrap">
                            <ul className="breadcumb-menu">
                                <li><Link to="/">Home</Link></li>
                                <li>Blogs</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <section className="vs-blog-wrapper space-top space-extra-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="vs-blog">
                                <div className="blog-img">
                                    <a to="blog-details.html"><img className="blog-img__img" src="assets/img/car/u1.JPEG" alt="Blog Image" /></a>
                                    <div className="vs-blog__meta">
                                        <span className="blog-date"> 27 <span>Nov. 2023</span></span>
                                        <ul>
                                            <li>
                                                <a className="blog-meta" to="blog-details.html">
                                                    <i className="fas fa-tags" /> 
                                                    Maintenance
                                                </a>
                                            </li>
                                            <li>
                                                <a className="blog-meta" to="blog-details.html">
                                                    <i className="fas fa-comment-dots" /> Used Motorcycle 
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="blog-content">
                                    <h2 className="blog-title">
                                        <a to="blog-details.html">How to Make Your Used Motorcycle Look Like New</a>
                                    </h2>
                                   <p>Buying a used motorcycle is an excellent way to enjoy the benefits of owning a bike without breaking the bank. However, a pre-owned bike may not</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="vs-blog">
                                <div className="blog-img">
                                    <a to="blog-details.html"><img className="blog-img__img" src="assets/img/car/u2.JPEG" alt="Blog Image" /></a>
                                    <div className="vs-blog__meta">
                                        <span className="blog-date"> 31 <span>Oct. 2023</span></span>
                                        <ul>
                                            <li>
                                                <a className="blog-meta" to="blog-details.html">
                                                    <i className="fas fa-tags" /> Maintenance
                                                </a>
                                            </li>
                                            <li>
                                                <a className="blog-meta" to="blog-details.html">
                                                    <i className="fas fa-comment-dots" /> Used Motorcycle 
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="blog-content">
                                    <h2 className="blog-title">
                                        <a to="blog-details.html">Your Guide to Cleaning Your Motorcycle</a>
                                    </h2>
                                    <p>First, it’s a good idea to park your bike somewhere flat with good drainage, like on gravel or concrete. Avoid parking it on grass, as the suds and</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="vs-blog">
                                <div className="blog-img">
                                    <a to="blog-details.html"><img className="blog-img__img" src="assets/img/car/u3.JPEG" alt="Blog Image" /></a>
                                    <div className="vs-blog__meta">
                                        <span className="blog-date">28<span>Aug. 2023</span></span>
                                        <ul>
                                            <li>
                                                <a className="blog-meta" to="blog-details.html">
                                                    <i className="fas fa-tags" /> Maintenance
                                                </a>
                                            </li>
                                            <li>
                                                <a className="blog-meta" to="blog-details.html">
                                                    <i className="fas fa-comment-dots" /> Used Motorcycle 
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="blog-content">
                                    <h2 className="blog-title">
                                        <a to="blog-details.html">Predicting a Used Motorcycle's Life Expectancy</a>
                                    </h2>
                                  <p>If you’re planning to buy a used motorcycle, it's important to evaluate its life expectancy before making the purchase. This will help you avoid spend</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="vs-blog">
                                <div className="blog-img">
                                    <a to="blog-details.html"><img className="blog-img__img" src="assets/img/car/u4.JPG" alt="Blog Image" /></a>
                                    <div className="vs-blog__meta">
                                        <span className="blog-date">04 <span>Sep, 2023</span></span>
                                        <ul>
                                            <li>
                                                <a className="blog-meta" to="blog-details.html">
                                                    <i className="fas fa-tags" /> Maintenance
                                                </a>
                                            </li>
                                            <li>
                                                <a className="blog-meta" to="blog-details.html">
                                                    <i className="fas fa-comment-dots" /> Used Motorcycle 
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="blog-content">
                                    <h2 className="blog-title">
                                        <a to="blog-details.html">Inspection Checklist for Buying a Used Motorcycle</a>
                                    </h2>
                                    When it comes to buying a used motorcycle, things can get overwhelming, particularly if you’re a first-time buyer. There are endless questions and
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="vs-blog">
                                <div className="blog-img">
                                    <a to="blog-details.html"><img className="blog-img__img" src="assets/img/car/u5.JPEG" alt="Blog Image" /></a>
                                    <div className="vs-blog__meta">
                                        <span className="blog-date">20 <span>Apr. 2024</span></span>
                                        <ul>
                                            <li>
                                                <a className="blog-meta" to="blog-details.html">
                                                    <i className="fas fa-tags" /> Maintenance
                                                </a>
                                            </li>
                                            <li>
                                                <a className="blog-meta" to="blog-details.html">
                                                    <i className="fas fa-comment-dots" /> Used Motorcycle 
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="blog-content">
                                    <h2 className="blog-title">
                                        <a to="blog-details.html">Benefits of Buying a Used Motorcycle</a>
                                    </h2>
                                    <p>Shopping for your first motorcycle? You may find yourself overwhelmed at the sheer variety available on the marketplace. And if you’re budget</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}

export default Blogs