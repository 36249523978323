import React from 'react'
import { Link } from "react-router-dom";
import HomeAbout from '../home/HomeAbout';
import HomeTestimonial from '../home/HomeTestimonial';


const Service = () => {
    return (
        <>
            <div className="breadcumb-wrapper" style={{ background: "url(assets/img/car/newService.5e2f082d.svg)" }}>
                <div className="container z-index-common">
                    <div className="breadcumb-content">
                        <h1 className="breadcumb-title">Service</h1>
                        <div className="breadcumb-menu-wrap">
                            <ul className="breadcumb-menu">
                                <li><Link to="/">Home</Link></li>
                                <li>Service</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <HomeAbout />
            <HomeTestimonial />


        </>
    )
}

export default Service