import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';

import { Link } from "react-router-dom";


export default function SliderHomeTestimonial() {
    return (
        <>
            <Swiper
                slidesPerView={1}
                spaceBetween={10}

                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                breakpoints={{
                    '@0.00': {
                        slidesPerView: 1,
                        spaceBetween: 10,
                    },
                    '@0.75': {
                        slidesPerView: 1,
                        spaceBetween: 20,
                    },
                    '@1.00': {
                        slidesPerView: 1,
                        spaceBetween: 40,
                    },
                    '@1.50': {
                        slidesPerView: 3,
                        spaceBetween: 50,
                    },
                }}
                modules={[Autoplay]}
                className="mySwiper"
            >


                <SwiperSlide>
                    <div className="col-auto">
                        <div className="client-block--style5">
                            <div className="client-block__img">
                                <br/>
                                <br/>
                                <img src="assets/img/car/profile.webp" alt="client member 3 1" />
                            </div>
                            <div className="client-block__content">
                                <div className="client-block__rating">
                                    <i className="fas fa-star" />
                                    <i className="fas fa-star" />
                                    <i className="fas fa-star" />
                                    <i className="fas fa-star" />
                                    <i className="fal fa-star" />
                                </div>
                                <p className="client-block__text">“ Buying through a third party was worrying, then I came to know about Auto World Bangalore. They helped me to get the choice of my vehicle in my range. The vehicle was delivered in nice condition. They helped me get RC transfer smoothly as well. I am enjoying the ride. Thanks, Auto World Bangalore. Thanks ”</p>
                                <h5 className="client-block__name">Divakar Naik</h5>
                                <br/>
                                <br/>
                              
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="col-auto">
                        <div className="client-block--style5">
                            <div className="client-block__img">
                            <br/>
                            <br/>
                                <img src="assets/img/car/profile.webp" alt="client member 3 1" />
                            </div>
                            <div className="client-block__content">
                                <div className="client-block__rating">
                                    <i className="fas fa-star" />
                                    <i className="fas fa-star" />
                                    <i className="fas fa-star" />
                                    <i className="fas fa-star" />
                                    <i className="fal fa-star" />
                                </div>
                                <p className="client-block__text">“ I purchased a Bajaj pulsar last month, happy with their service so far, good going big thanks to the team; they helped me a lot, got 3 vehicle test rides and then finally chose one they suggested to me- an awesome bike. I got my RC card within 20 days, a 7days buy protection, and a six-month engine and gearbox warranty. A big thanks to Auto World Bangalore. ”</p>
                                <h5 className="client-block__name">Abhi Das</h5>
                                <br/>
                            
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="col-auto">
                        <div className="client-block--style5">
                            <div className="client-block__img">
                            <br/>
                            <br/>
                                <img src="assets/img/car/profile.webp" alt="client member 3 1" />
                            </div>
                            <div className="client-block__content">
                                <div className="client-block__rating">
                                    <i className="fas fa-star" />
                                    <i className="fas fa-star" />
                                    <i className="fas fa-star" />
                                    <i className="fas fa-star" />
                                    <i className="fal fa-star" />
                                </div>
                                <p className="client-block__text">“ Auto World Bangalore has a wonderful team. The guys are very cooperative and fast; this is the best part. Always there to help you with every problem. They take care of everything. I seriously recommend coming over to Auto World Bangalore rather than any other second hand bike shop. ”</p>
                                <h5 className="client-block__name">Prithawayan Sinha</h5>
                                <br/>
                                <br/>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="col-auto">
                        <div className="client-block--style5">
                            <div className="client-block__img">
                            <br/>
                            <br/>
                                <img src="assets/img/car/profile.webp" alt="client member 3 1" />
                            </div>
                            <div className="client-block__content">
                                <div className="client-block__rating">
                                    <i className="fas fa-star" />
                                    <i className="fas fa-star" />
                                    <i className="fas fa-star" />
                                    <i className="fas fa-star" />
                                    <i className="fal fa-star" />
                                </div>
                                <p className="client-block__text">“ Hi all, I bought a Honda unicorn in 2019 from Auto World Bangalore. It's really good; I used it for nearly 2years and sold it back to Auto World Bangalore via doorstep service. Checking people and pickup people came on the same day because I wanted to sell within a day. They made the process very simple. I sold it at a good price. Thank you so much for the transparency and support. ”</p>
                                <h5 className="client-block__name">Balaji</h5>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="col-auto">
                        <div className="client-block--style5">
                            <div className="client-block__img">
                            <br/>
                            <br/>
                                <img src="assets/img/car/profile.webp" alt="client member 3 1" />
                            </div>
                            <div className="client-block__content">
                                <div className="client-block__rating">
                                    <i className="fas fa-star" />
                                    <i className="fas fa-star" />
                                    <i className="fas fa-star" />
                                    <i className="fas fa-star" />
                                    <i className="fal fa-star" />
                                </div>
                                <p className="client-block__text">“ Purchasing from Auto World Bangalore is a win-win deal for the seller as well as the buyer. Proper guidance was given to me to make a relevant purchase. I am happy with the purchase from Auto World Bangalore. Looking forward to good service in future as well for a good experience with their service. ”</p>
                                <h5 className="client-block__name">Abhinav Khare</h5>
                                <br/>
                                <br/>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>


            </Swiper>
        </>
    );
}
