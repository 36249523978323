import React from 'react'
import { Link } from "react-router-dom";
import WhatsappIcon from '../../pages/WhatsappIcon';


const Footer = () => {
  return (
    <>
      <footer  className="footer--layout1 space-top z-index-common dsa"  data-bg-src="assets/img/bg/footer-1-1.jpg" >
        <div className="overlay" />
        {/* <div className="container">
          <div className="footer-top">
            <div className="row align-items-center justify-content-between">
              <div className="col-lg-3 mb-30">
                <div className="footer-logo text-center d-block text-lg-start">
                  <a to="index.html"><img src="assets/img/car/Auto_world_logo.png" alt="Garix" className="logo" /></a>
                </div>
              </div>
              <div className="col-lg-6 mb-30">
                <p className="footer-text">
                  Lorem ipsum dolor sit aimet. Quo velit eum minima id eligendi mollitia eum cupiditate
                  velit sit voluphbtatem consequuntur
                </p>
              </div>
              <div className="col-lg-3 mb-30">
                <div className="social-style1 text-center text-lg-end">
                  <a to="https://themeforest.net/" target="_blank"><i className="fab fa-facebook-f" /></a>
                  <a to="https://themeforest.net/" target="_blank"><i className="fab fa-instagram" /></a>
                  <a to="https://themeforest.net/" target="_blank"><i className="fab fa-pinterest-p" /></a>
                  <a to="https://themeforest.net/" target="_blank"><i className="fab fa-twitter" /></a>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="widget-area2 space-extra-bottom" >
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-md-6 col-lg-4 col-xl-4">
                <div className="widget footer-widget">
                  <h3 className="widget_title">Contact Info</h3>
                  <div className="footer-communicate">
                    <ul>
                      <li>
                        <a className="footer-communicate__text" href="tel:+91 9060059444">
                          <i className="fas fa-phone-alt" />
                          <span className="link">+91 9060059444</span>
                        </a>
                      </li>
                      <li><a className="footer-communicate__text" href="mailto:blr560029@gmail.com"><i className="fas fa-envelope" />
                        <span className="link">blr560029@gmail.com</span></a></li>
                      <li>
                        <div className="footer-communicate__text">
                          <i className="fas fa-map-marker-alt" />
                          <a style={{color:"white"}} href='https://maps.app.goo.gl/xQbmGeTHfVmtrwou7' target="_blank">
                          <span>Metro Pllar 176, Bmtc Bus Terminal, No 298, Outer Ring Rd, Opp. Btm, Old Madiwala, Madiwala, 1st Stage, Btm Layout, Bengaluru, Karnataka 560068</span></a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* <div className="col-md-6 col-lg-4 col-xl-3">
                <div className="widget footer-widget">
                  <h3 className="widget_title">Opening Hours</h3>
                  <div className="widget__schedule" style={{color:"white"}}>
                    <table className="table table-borderless">
                      <tbody><tr>
                        <td>Mon</td>
                        <td><span>: </span></td>
                        <td style={{color:"white"}}><span>09.00 - 06.00</span></td>
                      </tr>
                        <tr>
                          <td>Tue</td>
                          <td><span>: </span></td>
                          <td><span>10.00 - 07.00</span></td>
                        </tr>
                        <tr>
                          <td>Wed</td>
                          <td><span>: </span></td>
                          <td><span>08.00 - 05.00</span></td>
                        </tr>
                        <tr>
                          <td>Thu</td>
                          <td><span>: </span></td>
                          <td><span>09.30 - 06.30</span></td>
                        </tr>
                        <tr>
                          <td>Fri</td>
                          <td><span>: </span></td>
                          <td><span>08.30 - 05.30</span></td>
                        </tr>
                        <tr>
                          <td>Sat</td>
                          <td><span>: </span></td>
                          <td><span>09.00 - 06.00</span></td>
                        </tr>
                      </tbody></table>
                  </div>
                </div>
              </div> */}
              <div className="col-md-6 col-lg-3 col-xl-2">
                <div className="widget widget_nav_menu footer-widget">
                  <h3 className="widget_title">Useful Links</h3>
                  <div className="menu-all-pages-container">
                    <ul className="menu">
                      <li><Link to="/"><i className="far fa-angle-right" /> Home</Link></li>
                      <li><Link to="/about"><i className="far fa-angle-right" /> About Us </Link></li>
                      <li><Link to="/faq"><i className="far fa-angle-right" /> FAQs </Link></li>
                      <li><Link to="/blogs"><i className="far fa-angle-right" /> Blog</Link></li>
                      <li><Link to="/service"><i className="far fa-angle-right" /> Service</Link></li>
                      <li><Link to="/contact"><i className="far fa-angle-right" /> Contact</Link></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xl-3">
                <div className="widget footer-widget">
                  <h3 className="widget_title">Our Instagram</h3>
                  <div className="sidebar-gallery">
                    <a to="assets/img/footer/insta1.jpg" className="popup-image"><img src="assets/img/footer/insta1.jpg" alt="Gallery Image" className="w-100" />
                    </a>
                    <a to="assets/img/footer/insta2.jpg" className="popup-image"><img src="assets/img/footer/insta2.jpg" alt="Gallery Image" className="w-100" />
                    </a>
                    <a to="assets/img/footer/insta3.jpg" className="popup-image"><img src="assets/img/footer/insta3.jpg" alt="Gallery Image" className="w-100" />
                    </a>
                    <a to="assets/img/footer/insta4.jpg" className="popup-image"><img src="assets/img/footer/insta4.jpg" alt="Gallery Image" className="w-100" />
                    </a>
                    <a to="assets/img/footer/insta5.jpg" className="popup-image"><img src="assets/img/footer/insta5.jpg" alt="Gallery Image" className="w-100" />
                    </a>
                    <a to="assets/img/footer/insta6.jpg" className="popup-image"><img src="assets/img/footer/insta6.jpg" alt="Gallery Image" className="w-100" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-wrap style2">
          <div className="container">
            <p className="copyright-text"> <i className="fal fa-copyright" />
             <a href="tel:+918982471422"> 2024 Auto World Bangalore | Designed by Web solution & digital marketing</a>
            </p>
          </div>
        </div>
      </footer>




      <WhatsappIcon />

    </>
  )
}

export default Footer